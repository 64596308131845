body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  font-family: Arial, sans-serif;
  background: linear-gradient(135deg, #0052cc 0%, #003366 100%);
}

.App {
  text-align: center;
  background: white;
  padding: 50px;
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
}

.logo {
  width: 120px; /* Pas de grootte van het logo aan zoals nodig */
  margin-bottom: 20px;
}

.App h1 {
  margin-bottom: 10px;
  color: #333;
  font-size: 2em;
}

.App h2 {
  margin-bottom: 20px;
  color: #555;
  font-size: 1.5em;
}

.App p {
  margin-bottom: 20px;
  color: #777;
  font-size: 1.2em;
}

input {
  width: 80%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.form-group {
  display: flex;
  align-items: right;
  justify-content: flex-end;
  margin-bottom: 20px;
  width: 60%;
  margin-left: 15px;
  margin-right: 10px;
}

.form-group input {
  margin-right: 2px;
}

.form-group label {
  font-size: 14px;
  color: #555;
}

button {
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background: #003366;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background: #002244;
}

button[type="button"] {
  background: none;
  color: #003366;
  text-decoration: underline;
  cursor: pointer;
  padding: 0;
  border: none;
  font-size: 1em;
}

button[type="button"]:hover {
  color: #002244;
}

.success {
  color: green;
}

.error {
  color: red;
}

.privacy-policy h1 {
  padding-top: 20px;
  margin-top: 0;
}

.privacy-policy {
  padding-top: 40px;
  max-height: 80vh;
  overflow-y: auto;

}


@media only screen and (max-width: 768px) {
  .App {
    padding: 20px;
    border-radius: 10px;
  }

  .logo {
    width: 80px;
  }

  .App h1 {
    font-size: 1.5em;
  }

  .App h2 {
    font-size: 1.2em;
  }

  .App p {
    font-size: 1em;
  }

  input {
    width: 90%;
    padding: 8px;
  }

  .form-group {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-left: 0;
  }

  .form-group input[type="checkbox"] {
    margin-bottom: 10px;
  }

  .form-group label {
    font-size: 14px;
  }

  button {
    padding: 8px 16px;
    font-size: 14px;
  }
}